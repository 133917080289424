import { EditorState } from "draft-js";
import { TFunction } from "i18next";
import { getStateToMarkdown } from "src/components/BriefForm/utils";
import { MediaResourceType } from "src/components/shared/ResourceInput";
import { SkillLevelSelectionType } from "src/components/SkillSelection/types";
import * as yup from "yup";

import { IMediaResource, IProfessionalSituationResource, ITagResource } from "@sol/sdk";

import { CreateBriefStep, CreateBriefStepWithSummary } from "../constants";
import {
    CreateBriefAdditionalInformationStepPayload,
    CreateBriefDescriptionStepPayload,
    CreateBriefFormValues,
    CreateBriefSkillsStepPayload,
} from "../CreateBriefFormsManager";

/**
 * constants
 */
export const PROFESSIONAL_SITUATION_VALIDATION_RULES = {
    title: { max: 100 },
    targetedDomain: { max: 100 },
    problematic: { max: 500 },
    description: { max: 6000 },
};

export const CREATE_BRIEF_VALIDATION_RULES = {
    skillLevels: { min: 1 },
    title: { max: 100 },
    description: { max: 900 },
    context: { max: 6000 },
    pedagogicModalities: { max: 6000 },
    evaluationModalities: { max: 3000 },
    expectedWork: { max: 3000 },
    performanceCriteria: { max: 3000 },
    tags: { max: 10 },
};

/**
 * helpers
 */
export const getBriefSchemas = (t: TFunction) => ({
    "professional-situation": yup.object<IProfessionalSituationResource>().required(),
    skills: buildBriefSkillsSchema(t),
    description: buildBriefDescriptionSchema(t),
    "additional-information": buildBriefAdditionalInformationsSchema(),
});

const DRAFT_KEY = "isDraft";

// we check the value of DRAFT_KEY field to determine if the form fields are required
const shouldBeRequired = {
    is: true,
    then: (currentSchema: any) => currentSchema.notRequired(),
    otherwise: (currentSchema: any) => currentSchema.required(),
};

const getBriefDraftSchema = (t: TFunction) =>
    yup.object().shape({
        isDraft: yup.boolean().default(true),
        ...briefSkillsSchema,
        ...briefDescriptionSchema(t),
        ...briefAdditionalInformationsSchema,
    });

type DraftFormValues = Partial<
    CreateBriefSkillsStepPayload & CreateBriefDescriptionStepPayload & CreateBriefAdditionalInformationStepPayload
>;

export const isBriefDraftSchemaValid = (t: TFunction, values: DraftFormValues) => {
    return getBriefDraftSchema(t).validateSync(values);
};

/**
 * create professional situations step
 */
const professionalSituationSchema = {
    title: yup.string().max(PROFESSIONAL_SITUATION_VALIDATION_RULES.title.max).required(),
    language: yup.string().required(),
    targetedDomain: yup.string().max(PROFESSIONAL_SITUATION_VALIDATION_RULES.targetedDomain.max).required(),
    problematic: yup.string().max(PROFESSIONAL_SITUATION_VALIDATION_RULES.problematic.max).required(),
    description: yup.string().max(PROFESSIONAL_SITUATION_VALIDATION_RULES.description.max).required(),
    skills: yup.array().of(yup.string()).required(),
};

export const buildCreateProfessionalSituationSchema = () => yup.object().shape(professionalSituationSchema).required();

/**
 * create brief skill levels step
 */
const briefSkillsSchema = (t: TFunction) => ({
    skillLevels: yup
        .array<SkillLevelSelectionType>()
        .min(CREATE_BRIEF_VALIDATION_RULES.skillLevels.min, t("component.shared.AddBriefSkillLevelsView.minValidation"))
        .when(DRAFT_KEY, shouldBeRequired),
});

const buildBriefSkillsSchema = (t: TFunction) => yup.object().shape(briefSkillsSchema(t)).required();

// we check the value of DRAFT_KEY field to determine if the form fields are required
const shouldWysiwygBeRequired = (name: string, maxLength: number, t: TFunction) => ({
    is: true,
    then: (schema: any) =>
        schema.test(
            "wysiwyg length",
            t(`component.AddBriefDescriptionForm.${name}.error.length`, { maxLength }),
            (value: EditorState) => {
                const markdown = getStateToMarkdown(value);

                if (markdown) {
                    return markdown.length <= maxLength;
                } else {
                    return true;
                }
            },
        ),
    otherwise: (schema: any) =>
        schema.test(
            "wysiwyg length and required",
            t(`component.AddBriefDescriptionForm.${name}.error.requiredAndLength`, { maxLength }),
            (value: EditorState) => {
                const markdown = getStateToMarkdown(value);

                return !!(markdown && markdown.length && markdown.length <= maxLength);
            },
        ),
});

/**
 * create brief description step
 */
const briefDescriptionSchema = (t: TFunction) => {
    const {
        title,
        description,
        context,
        pedagogicModalities,
        evaluationModalities,
        expectedWork,
        performanceCriteria,
    } = CREATE_BRIEF_VALIDATION_RULES;

    return {
        title: yup.string().max(title.max).required(),
        description: yup.string().max(description.max).when(DRAFT_KEY, shouldBeRequired),
        context: yup.mixed<EditorState>().when(DRAFT_KEY, shouldWysiwygBeRequired("context", context.max, t)),
        pedagogicModalities: yup
            .mixed<EditorState>()
            .when(DRAFT_KEY, shouldWysiwygBeRequired("pedagogicModalities", pedagogicModalities.max, t)),
        evaluationModalities: yup.string().max(evaluationModalities.max).when(DRAFT_KEY, shouldBeRequired),
        expectedWork: yup.string().max(expectedWork.max).when(DRAFT_KEY, shouldBeRequired),
        performanceCriteria: yup.string().max(performanceCriteria.max).when(DRAFT_KEY, shouldBeRequired),
    };
};

const buildBriefDescriptionSchema = (t: TFunction) => yup.object().shape(briefDescriptionSchema(t)).required();

/**
 * create brief additional informations step
 */
const briefAdditionalInformationsSchema = {
    resources: yup.array<MediaResourceType>(),
    tags: yup.array<ITagResource>().max(CREATE_BRIEF_VALIDATION_RULES.tags.max),
    image: yup.object<IMediaResource>(),
};

export const buildBriefAdditionalInformationsSchema = () => yup.object().shape(briefAdditionalInformationsSchema);

/**
 * test form values
 */
export type BriefStepStatus = "completed" | "current" | "disabled";
export type BriefStepsStatusObject = {
    [key in CreateBriefStep]: BriefStepStatus;
};

export const getBriefStepsStatuses = (
    state: CreateBriefFormValues,
    currentStep: CreateBriefStepWithSummary,
    t: TFunction,
): BriefStepsStatusObject => {
    const professionalSituationSchema = yup.mixed<IProfessionalSituationResource>().required();
    const skillsSchema = buildBriefSkillsSchema(t);
    const descriptionSchema = buildBriefDescriptionSchema(t);
    const additionnalInformationSchema = buildBriefAdditionalInformationsSchema();

    type Schema =
        | typeof professionalSituationSchema
        | typeof skillsSchema
        | typeof descriptionSchema
        | typeof additionnalInformationSchema;

    const getStatus = (step: CreateBriefStep, schema: Schema) => {
        return currentStep === step ? "current" : schema.isValidSync(state[step]) ? "completed" : "disabled";
    };

    return {
        "professional-situation": getStatus("professional-situation", professionalSituationSchema),
        skills: getStatus("skills", skillsSchema),
        description: getStatus("description", descriptionSchema),
        "additional-information": getStatus("additional-information", additionnalInformationSchema),
    };
};
