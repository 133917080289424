import { FC } from "react";
import { useTranslation } from "react-i18next";
import { BlockSkeleton } from "src/components/skeletons";
import { BriefSteps } from "src/enums";
import { Text } from "src/uikit";
import { Theme } from "src/uikit/theme";
import styled from "styled-components";

import { Check } from "@sol/icons";
import { Button } from "@sol/uikit";

type StepStatus = "disabled" | "completed" | "current";

type Step = {
    label: string;
    status: StepStatus;
    order: number;
};

type StepsGroup = {
    title: string;
    steps: Step[];
};

type StepProps = {
    step: Step;
    className?: string;
    onBackClick?: () => void;
    currentStep?: string;
};

const StepGroupLabel = styled(Text)`
    color: ${({ theme }) => theme.palette.grey.light};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const GroupFrame = styled.li`
    list-style: none;

    & + & {
        padding-top: ${({ theme }) => theme.spacing[6]};
        margin-top: ${({ theme }) => theme.spacing[6]};
        border-top: 1px solid ${({ theme }) => theme.palette.grey.lightest};
        & > ul > li:not(:last-child) {
            &:after {
                content: "";
                height: 36px;
                width: 4px;
                justify-self: center;
                border-right: 4px dotted ${({ theme }) => theme.palette.grey.lightest};
            }
        }
    }
`;

const Badge = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${({ theme }) => theme.shape.borderRadius.large};
    height: 32px;
    width: 32px;
    min-width: 32px;
    border-width: 1px;
    border-style: solid;
    background-color: ${({ theme }) => theme.palette.grey.lightest};
`;

const BackButton = styled(Button)`
    display: flex;
    align-items: center;
    grid-column: span 2 / span 2;
    text-align: center;
    flex-direction: row;
    padding: 4px 8px;
    color: ${({ theme }) => theme.palette.purple.base};
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
`;

const Loader = styled(BlockSkeleton)`
    height: 472px;
    margin-top: ${({ theme }) => theme.spacing[6]};
    border: 1px solid transparent;
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
`;

type StatusVariant = {
    textColor: string;
    badgeColor: string;
    borderColor: string;
};

const getStatusVariant = (status: StepStatus, theme: Theme): StatusVariant => {
    const variants = {
        completed: {
            textColor: theme.palette.green.base,
            badgeColor: theme.palette.green.base,
            borderColor: theme.palette.green.base,
        },
        current: {
            badgeColor: theme.palette.green.base,
            borderColor: theme.palette.green.base,
            textColor: theme.palette.black.base,
        },
        disabled: {
            textColor: theme.palette.grey.base,
            badgeColor: theme.palette.grey.lighter,
            borderColor: theme.palette.grey.lightest,
        },
    };

    return variants[status];
};

const Step = styled(({ step, className, onBackClick, currentStep }: StepProps) => {
    const { order, label, status } = step;
    const [t] = useTranslation();
    const { PROFESSIONAL_SITUATION } = BriefSteps;

    const backProfessionalSituationStep = (
        <BackButton
            variant="primary"
            onClick={onBackClick}
            data-cy="create-brief-aside-back-professional-situation-step"
        >
            {t("component.shared.AddBriefSkillLevelsView.addProfessionalSituation")}
        </BackButton>
    );
    return (
        <li className={className} data-cy={`step-${status}`}>
            <Badge>{status === "completed" ? <Check /> : <Text>{order}</Text>}</Badge>
            <Text variant="label">{label}</Text>
            {order === 1 && currentStep !== PROFESSIONAL_SITUATION ? backProfessionalSituationStep : null}
            {status === "current" && <p className="sr-only">{t("component.CreateBriefLayout.steps.currentStep")}</p>}
        </li>
    );
})`
    display: grid;
    grid-template-columns: repeat(2, minmax(min-content, max-content));
    align-items: center;
    gap: ${({ theme }) => theme.spacing[4]};
    list-style: none;
}

    ${({ theme, step }) => {
        const { badgeColor, borderColor, textColor } = getStatusVariant(step.status, theme);

        return `
            --text-color: ${textColor};
            --icon-color: ${theme.palette.green.base};

            ${Badge} {
                --text-color: ${badgeColor};
                border-color: ${borderColor}
            }
        `;
    }}
`;

type Props = {
    stepsGroups: StepsGroup[];
    className?: string;
    isLoading?: boolean;
    onBackClick?: () => void;
    currentStep?: string;
};

const CreateBriefStepsAside: FC<Props> = ({
    stepsGroups,
    children,
    className,
    isLoading,
    onBackClick,
    currentStep,
}) => {
    return isLoading ? (
        <Loader />
    ) : (
        <aside className={className} data-cy="create-brief-step-aside">
            {stepsGroups.length && (
                <ul>
                    {stepsGroups.map(group => (
                        <GroupFrame key={group.title}>
                            <StepGroupLabel variant="subheading">{group.title}</StepGroupLabel>
                            <ul>
                                {group.steps.map(step => (
                                    <Step
                                        step={step}
                                        key={step.label}
                                        onBackClick={onBackClick}
                                        currentStep={currentStep}
                                    />
                                ))}
                            </ul>
                        </GroupFrame>
                    ))}
                </ul>
            )}
            {children}
        </aside>
    );
};

export default styled(CreateBriefStepsAside)`
    padding: ${({ theme }) => theme.spacing[6]};
    background-color: ${({ theme }) => theme.palette.white.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    width: 100%;

    ul {
        padding-left: 0;
        margin: 0;
    }

    ${Step} + ${Step} {
        margin-top: ${({ theme }) => theme.spacing[4]};
    }

    margin-top: ${({ theme }) => theme.spacing[6]};
`;
