import clsx from "clsx";
import { RichUtils } from "draft-js";
import React, { useCallback } from "react";

const useToggleBlockType = props => {
    const { blockType, getEditorState, setEditorState } = props;

    return useCallback(
        event => {
            event.preventDefault();
            setEditorState(RichUtils.toggleBlockType(getEditorState(), blockType));
        },
        [blockType, getEditorState, setEditorState],
    );
};

const preventBubblingUp = event => {
    event.preventDefault();
};

const isBlockTypeActive = props => {
    const { getEditorState, blockType } = props;
    // if the button is rendered before the editor
    if (!getEditorState) {
        return false;
    }

    const editorState = getEditorState();
    const type = editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType();
    return type === blockType;
};

const BlockTypeButton = props => {
    const { theme, children } = props;
    const handleClick = useToggleBlockType(props);

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div className={theme.buttonWrapper} onMouseDown={preventBubblingUp}>
            <button
                className={clsx(theme.button, {
                    [theme.active]: isBlockTypeActive(props),
                })}
                onClick={handleClick}
                type="button"
            >
                {children}
            </button>
        </div>
    );
};

export default BlockTypeButton;
