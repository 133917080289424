import { ContentBlock, EditorState, Modifier, genKey } from "draft-js";
import { Map } from "immutable";
import defaults from "lodash/defaults";

const getSelectedBlock = editorState => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const startKey = selection.getStartKey();
    const currentBlock = contentState.getBlockForKey(startKey);

    return currentBlock;
};

const addNewBlockAt = (editorState, pivotBlockKey, contentBlock = {}) => {
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();
    const block = blockMap.get(pivotBlockKey);
    const blocksBefore = blockMap.toSeq().takeUntil(v => v === block);
    const blocksAfter = blockMap
        .toSeq()
        .skipUntil(v => v === block)
        .rest();

    const newBlock = new ContentBlock(
        defaults({}, contentBlock, {
            key: genKey(),
            type: "unstyled",
            text: "",
            characterList: block.getCharacterList().slice(0, 0),
            depth: 0,
            data: new Map({}),
        }),
    );

    const newBlockKey = newBlock.getKey();

    const newBlockMap = blocksBefore
        .concat(
            [
                [pivotBlockKey, block],
                [newBlockKey, newBlock],
            ],
            blocksAfter,
        )
        .toOrderedMap();

    const selection = editorState.getSelection();

    const newContent = content.merge({
        blockMap: newBlockMap,
        selectionBefore: selection,
        selectionAfter: selection.merge({
            anchorKey: newBlockKey,
            anchorOffset: 0,
            focusKey: newBlockKey,
            focusOffset: 0,
            isBackward: false,
        }),
    });

    return EditorState.push(editorState, newContent, "split-block");
};

const pasteText = (editorState, text) => {
    const selection = editorState.getSelection();

    const contentState = editorState.getCurrentContent();

    const newContentState = Modifier.replaceText(contentState, selection, text);

    return EditorState.push(editorState, newContentState, "insert-characters");
};

const getContentLength = contentState => ((contentState && contentState.getPlainText()) || "").length;

const splitBlockFromCurrentSelection = editorState => {
    const selection = editorState.getSelection();
    if (selection.isCollapsed()) {
        const contentState = editorState.getCurrentContent();
        // Allow us to split a block from the cursor position
        const newContent = Modifier.splitBlock(contentState, selection);

        return EditorState.push(editorState, newContent, "split-block");
    }

    return editorState;
};

const HandlerStatus = {
    HANDLED: "handled",
    NOT_HANDLED: "not-handled",
};

export { getSelectedBlock, addNewBlockAt, pasteText, HandlerStatus, getContentLength, splitBlockFromCurrentSelection };
