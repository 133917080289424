import clsx from "clsx";
import { RichUtils } from "draft-js";
import React, { useCallback } from "react";

const useToggleStyle = props => {
    const { inlineStyle, getEditorState, setEditorState } = props;

    return useCallback(
        event => {
            event.preventDefault();
            setEditorState(RichUtils.toggleInlineStyle(getEditorState(), inlineStyle));
        },
        [inlineStyle, getEditorState, setEditorState],
    );
};

const preventBubblingUp = event => {
    event.preventDefault();
};

const isStyleActive = props => {
    const { getEditorState, inlineStyle } = props;
    return getEditorState().getCurrentInlineStyle().has(inlineStyle);
};

const InlineStyleButton = props => {
    const { theme, children } = props;
    const handleClick = useToggleStyle(props);
    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div className={theme.buttonWrapper} onMouseDown={preventBubblingUp}>
            <button
                className={clsx(theme.button, {
                    [theme.active]: isStyleActive(props),
                })}
                onClick={handleClick}
                type="button"
            >
                {children}
            </button>
        </div>
    );
};

InlineStyleButton.displayName = "InlineStyleButton";

export default InlineStyleButton;
