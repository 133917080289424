import { isArray } from "lodash";
import { FocusEvent } from "react";
import { useId } from "react-aria";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ImageInput from "src/components/ImageInput";
import Attachment from "src/components/shared/Attachment";
import TagsPicker from "src/components/shared/pickers/TagsPicker";
import { Text } from "src/uikit";
import styled from "styled-components";

import { Arrow } from "@sol/icons";
import { IMediaResource, MediaType, useCategories } from "@sol/sdk";
import { Button, Grid, GridColumn, InputDecorator } from "@sol/uikit";
import { Typography } from "@sol/uikit/core/Typography";

import ResourceInput from "../../../shared/ResourceInput";
import { CreateBriefAdditionalInformationStepPayload } from "../CreateBriefFormsManager";

const Content = styled.div`
    padding: ${({ theme }) => theme.spacing[7]};
    margin-top: ${({ theme }) => theme.spacing[6]};
    background-color: ${({ theme }) => theme.palette.white.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    width: 100%;

    > ${InputDecorator}:not(:first-child) {
        margin-top: ${({ theme }) => theme.spacing[9]};
    }
`;

const NoAttachment = styled.div`
    padding: ${({ theme }) => theme.spacing[4]};
    margin-top: ${({ theme }) => theme.spacing[5]};
    background-color: ${({ theme }) => theme.palette.grey.lightest};
    --text-color: ${({ theme }) => theme.palette.grey.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    text-align: center;
    width: 100%;
`;

const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacing[5]};
`;

const SUPPORTED_MIME_TYPES = ["image/jpeg", "image/png", "image/gif"];

type Props = {
    onBackClick: () => void;
    onStepSubmit: (values: CreateBriefAdditionalInformationStepPayload) => void;
    className?: string;
};

const AddAdditionalInformation = ({ onBackClick, onStepSubmit, className }: Props) => {
    const [t] = useTranslation();
    const visualOfBriefTitleId = useId();
    const ressourcesTitleId = useId();

    const { data: categoriesData } = useCategories({});
    const categories = categoriesData?.["hydra:member"] || [];

    const { control, handleSubmit, formState } = useFormContext();

    return (
        <form onSubmit={handleSubmit(onStepSubmit)} className={className} data-cy="add-additional-information-form">
            <Content>
                <Controller
                    control={control}
                    name="resources"
                    render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
                        return (
                            <>
                                <InputDecorator error={isArray(error) ? undefined : error}>
                                    {({ errorId }) => (
                                        <>
                                            <Typography tag="h3" variant="label" id={ressourcesTitleId}>
                                                {t("component.AddAdditionalInformation.resourcesLabel")}
                                            </Typography>
                                            <ResourceInput
                                                resources={value}
                                                onResourceAdd={attachment => onChange([...value, attachment])}
                                                aria-describedby={errorId}
                                                aria-invalid={invalid}
                                                titleSectionId={ressourcesTitleId}
                                                showTitleField
                                            />
                                        </>
                                    )}
                                </InputDecorator>
                                {value.length > 0 ? (
                                    <Grid as="ul">
                                        {value.map(({ "@id": id, size, title, url, type }: IMediaResource) => {
                                            const isFileKind = type === MediaType.FILE;

                                            return (
                                                <GridColumn key={id} col={6} as="li">
                                                    <Attachment
                                                        kind={isFileKind ? "media" : "link"}
                                                        url={url}
                                                        title={title}
                                                        size={size}
                                                        onClose={() => {
                                                            onChange(
                                                                value.filter(
                                                                    (resource: IMediaResource) => resource.url !== url,
                                                                ),
                                                            );
                                                        }}
                                                        data-cy="ressource-item"
                                                    />
                                                </GridColumn>
                                            );
                                        })}
                                    </Grid>
                                ) : (
                                    <NoAttachment>
                                        <Text>{t(t("component.AddAdditionalInformation.noResource"))}</Text>
                                    </NoAttachment>
                                )}
                            </>
                        );
                    }}
                />

                <Controller
                    control={control}
                    name="tags"
                    render={({ field: { onBlur, onChange, value }, fieldState: { invalid, error } }) => {
                        return (
                            <InputDecorator error={isArray(error) ? undefined : error}>
                                {({ errorId }) => (
                                    <>
                                        <Typography tag="h3" variant="label">
                                            {t("component.AddAdditionalInformation.tagsLabel")}
                                        </Typography>
                                        <TagsPicker
                                            categories={categories}
                                            onBlur={(event: FocusEvent<HTMLDivElement>) => {
                                                // React 17 internally uses the browser's focusin and focusout events for onFocus and onBlur events
                                                // see https://blog.saeloun.com/2021/05/14/react-17-uses-browse-focusin-focusout-for-onfocus-onblur
                                                if (!event.currentTarget.contains(event.relatedTarget)) {
                                                    onBlur();
                                                }
                                            }}
                                            value={value ?? []}
                                            onChange={
                                                /* Disable tags picker on submit */
                                                formState.isSubmitting ? () => undefined : onChange
                                            }
                                            data-cy="brief-tags"
                                            aria-describedby={errorId}
                                            aria-invalid={invalid}
                                        />
                                    </>
                                )}
                            </InputDecorator>
                        );
                    }}
                />
                <Controller
                    control={control}
                    name="image"
                    render={({ field: { onChange, value }, fieldState: { invalid, error } }) => {
                        return (
                            <InputDecorator error={error}>
                                {({ errorId }) => (
                                    <>
                                        <Typography tag="h3" variant="label" id={visualOfBriefTitleId}>
                                            {t("component.AddAdditionalInformation.imageLabel")}
                                        </Typography>
                                        <ImageInput
                                            input={{
                                                value,
                                                onChange,
                                            }}
                                            supportedMIMETypes={SUPPORTED_MIME_TYPES}
                                            data-cy="brief-image"
                                            aria-describedby={errorId}
                                            aria-invalid={invalid}
                                            titleSectionId={visualOfBriefTitleId}
                                        />
                                    </>
                                )}
                            </InputDecorator>
                        );
                    }}
                />
            </Content>

            <Actions>
                <Button outlined variant="primary" type="button" onClick={onBackClick}>
                    <Arrow direction="left" />
                    {t("component.AddAdditionalInformation.previousStep")}
                </Button>

                <Button filled variant="primary" type="submit" disabled={formState.isSubmitting}>
                    {t("component.AddAdditionalInformation.submit")}
                    <Arrow direction="right" />
                </Button>
            </Actions>
        </form>
    );
};

export default styled(AddAdditionalInformation)`
    ${Grid} {
        gap: ${({ theme }) => theme.spacing[5]};
        padding-left: 0;
        margin: ${({ theme }) => `${theme.spacing[5]} 0 0 0`};
        list-style: none;
    }
`;
