/* eslint-disable react/display-name */
import { Key, ReactNode, useMemo } from "react";
import { Item } from "react-stately";

import { useLanguages } from "@sol/sdk";
import { Select } from "@sol/uikit/molecules/Forms/Select";
import { SelectVariantsProps } from "@sol/uikit/molecules/Forms/Select/variants";

type Props = {
    label: string | ReactNode;
    onSelect: (value: string | undefined) => void;
    disabled?: boolean;
    defaultValue: string;
    value?: string;
    locale?: boolean;
    isLoading?: boolean;
    className?: string;
} & SelectVariantsProps;

const LanguageSelect = ({
    label,
    onSelect,
    variant,
    disabled,
    defaultValue,
    locale: useLocaleAsKey,
    value,
    className,
    ...props
}: Props) => {
    const { data: languages, isLoading } = useLanguages({});

    const languageOptions = useMemo(
        () =>
            languages?.["hydra:member"]?.map(language => {
                const { title, locale } = language;

                return {
                    key: useLocaleAsKey ? locale : language["@id"],
                    label: title,
                    component: () => (
                        <span className="flex items-center gap-3" lang={locale}>
                            <img src={`/static/flags/${locale.substring(0, 2)}.svg`} alt="" className="w-6" />
                            {title}
                        </span>
                    ),
                };
            }) ?? [],
        [languages, useLocaleAsKey],
    );

    return (
        <Select
            variant={variant}
            items={languageOptions}
            isDisabled={disabled}
            className={className}
            width="fixed"
            label={label}
            defaultSelectedKey={value ?? defaultValue}
            onSelectionChange={(key: Key) => {
                onSelect(key as string);
            }}
            isLoading={isLoading}
            {...props}
        >
            {item => (item.component ? <Item>{item.component()}</Item> : <Item>{item.label}</Item>)}
        </Select>
    );
};

export default LanguageSelect;
