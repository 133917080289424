import styled from "styled-components";

const Anchor = styled.a`
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.purple.base};
`;

Anchor.displayName = "Anchor";

export default Anchor;
