import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getStateToMarkdown } from "src/components/BriefForm/utils";
import Wysiwyg from "src/components/inputs/Wysiwyg";
import styled from "styled-components";

import { Arrow } from "@sol/icons";
import { Button, InputDecorator, TextArea, TextInput } from "@sol/uikit";

import { CreateBriefDescriptionStepPayload } from "../CreateBriefFormsManager";
import { CREATE_BRIEF_VALIDATION_RULES } from "./validations";

const Card = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[8]};
    background-color: ${({ theme }) => theme.palette.white.base};
    padding: ${({ theme }) => theme.spacing[7]};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacing[5]};
`;

type FormFieldDataType = { name: string; max: number; type: "text" | "textarea" | "wysiwyg" };

const formData: FormFieldDataType[] = [
    { name: "title", max: CREATE_BRIEF_VALIDATION_RULES.title.max, type: "text" },
    { name: "description", max: CREATE_BRIEF_VALIDATION_RULES.description.max, type: "textarea" },
    { name: "context", max: CREATE_BRIEF_VALIDATION_RULES.context.max, type: "wysiwyg" },
    { name: "pedagogicModalities", max: CREATE_BRIEF_VALIDATION_RULES.pedagogicModalities.max, type: "wysiwyg" },
    { name: "evaluationModalities", max: CREATE_BRIEF_VALIDATION_RULES.evaluationModalities.max, type: "textarea" },
    { name: "expectedWork", max: CREATE_BRIEF_VALIDATION_RULES.expectedWork.max, type: "textarea" },
    { name: "performanceCriteria", max: CREATE_BRIEF_VALIDATION_RULES.performanceCriteria.max, type: "textarea" },
];

type Props = {
    onStepSubmit: (values: CreateBriefDescriptionStepPayload) => void;
    onBackClick: () => void;
    className?: string;
};

const AddBriefDescriptionForm = ({ onStepSubmit, onBackClick, className }: Props) => {
    const [t] = useTranslation();

    const { control, handleSubmit, formState } = useFormContext();

    return (
        <form className={className} onSubmit={handleSubmit(onStepSubmit)}>
            <Card>
                {formData.map(field => (
                    <Controller
                        key={field.name}
                        control={control}
                        name={field.name}
                        render={({ field: { name, value, ...fieldProps }, fieldState: { invalid, error } }) => (
                            <InputDecorator
                                required
                                htmlFor={name}
                                label={t(`component.AddBriefDescriptionForm.${name}.label`)}
                                helper={t(`component.AddBriefDescriptionForm.${name}.helper`, {
                                    count:
                                        field.type === "wysiwyg"
                                            ? getStateToMarkdown(value)?.length ?? 0
                                            : value?.length ?? 0,
                                    max: field.max,
                                })}
                                error={error}
                            >
                                {({ errorId }) => (
                                    <>
                                        {field.type === "text" && (
                                            <TextInput
                                                {...fieldProps}
                                                id={name}
                                                value={value}
                                                data-cy={`add-brief-description-${name}`}
                                                placeholder={t(`component.AddBriefDescriptionForm.${name}.placeholder`)}
                                                aria-describedby={errorId}
                                                aria-invalid={invalid}
                                                variant={invalid ? "error" : "default"}
                                            />
                                        )}
                                        {field.type === "textarea" && (
                                            <TextArea
                                                {...fieldProps}
                                                value={value}
                                                minRows={4}
                                                id={name}
                                                data-cy={`add-brief-description-${name}`}
                                                placeholder={t(`component.AddBriefDescriptionForm.${name}.placeholder`)}
                                                aria-describedby={errorId}
                                                aria-invalid={invalid}
                                                variant={invalid ? "error" : "default"}
                                            />
                                        )}
                                        {field.type === "wysiwyg" && (
                                            <Wysiwyg
                                                {...fieldProps}
                                                data-cy={`add-brief-description-${name}`}
                                                placeholder={t(`component.AddBriefDescriptionForm.${name}.placeholder`)}
                                                name={name}
                                                value={value}
                                                isError={invalid}
                                            />
                                        )}
                                    </>
                                )}
                            </InputDecorator>
                        )}
                    />
                ))}
            </Card>
            <Actions>
                <Button outlined variant="primary" type="button" onClick={onBackClick}>
                    <Arrow direction="left" />
                    {t("component.AddBriefDescriptionForm.back")}
                </Button>
                <Button
                    filled
                    variant="primary"
                    type="submit"
                    data-cy="submit-brief-description-form"
                    disabled={formState.isSubmitting}
                >
                    {t("component.AddBriefDescriptionForm.next")}
                    <Arrow direction="right" />
                </Button>
            </Actions>
        </form>
    );
};

export default styled(memo(AddBriefDescriptionForm))``;
