/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";

const isUrl = value => {
    const regEx = new RegExp(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    );

    return value.match(regEx);
};

export default class AddLinkForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",
            isInvalid: false,
        };
    }

    componentDidMount() {
        this.input.focus();
    }

    onRef = node => {
        this.input = node;
    };

    onChange = ({ target: { value } }) => {
        const nextState = { value };
        if (this.state.isInvalid && !isUrl(value)) {
            nextState.isInvalid = false;
        }
        this.setState(nextState);
    };

    onClose = () => {
        return this.props.onOverrideContent(undefined);
    };

    onKeyDown = e => {
        if (e.key === "Enter") {
            e.preventDefault();
            this.submit();
        } else if (e.key === "Escape") {
            e.preventDefault();
            this.onClose();
        }
    };

    submit() {
        const { getEditorState, setEditorState, onSubmit } = this.props;
        const { value } = this.state;

        onSubmit(value, { getEditorState, setEditorState });
        // setEditorState(
        //     EditorUtils.createLinkAtSelection(getEditorState(), value),
        // );
        this.input.blur();
        this.onClose();
    }

    render() {
        const { theme, placeholder } = this.props;
        const { value } = this.state;
        // const className = isInvalid
        //     ? clsx(theme.input, theme.inputInvalid)
        //     : theme.input;

        return (
            <input
                className={theme.input}
                onBlur={this.onClose}
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
                placeholder={placeholder}
                ref={this.onRef}
                type="text"
                value={value}
            />
        );
    }
}
