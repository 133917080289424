import { FC, ReactNode, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { BlockSkeleton } from "src/components/skeletons";
import { Text } from "src/uikit";
import styled from "styled-components";

import { Info, SvgIcon } from "@sol/icons";
import { Grid, GridColumn } from "@sol/uikit";

import { CreateBriefStepWithSummary } from "./constants";
import CreateBriefStepsAside from "./CreateBriefStepsAside";

const Subtitle = styled(Text)`
    margin: ${({ theme }) => theme.spacing[6]} 0;
`;

const DownloadGuide = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[3]};

    --text-color: ${({ theme }) => theme.palette.purple.base};
    --icon-color: ${({ theme }) => theme.palette.purple.base};

    ${SvgIcon} {
        background-color: ${({ theme }) => theme.palette.white.base};
    }
`;

const StickyContainer = styled.div`
    position: sticky;
    top: 86px; // header height + parent container margin
`;

type Props = {
    title: string;
    subtitle: string;
    index: number;
    currentStep: CreateBriefStepWithSummary;
    skeleton?: ReactNode;
    form: ReactNode;
    className?: string;
    isLoading?: boolean;
};

const CreateBriefLayout: FC<Props> = ({
    currentStep,
    title,
    form,
    index,
    subtitle,
    children,
    className,
    isLoading,
    skeleton,
}) => {
    const [t] = useTranslation();

    const fallback = skeleton ?? <BlockSkeleton />;

    return (
        <Grid className={className} data-cy={`create-brief:${currentStep}`}>
            <GridColumn col={9}>
                <Text as="h1" variant="h3">
                    {title}
                </Text>
                <Subtitle as="h2" variant="h4">
                    {t("component.CreateBriefLayout.step", { index })} - {subtitle}
                </Subtitle>
                {isLoading ? fallback : <Suspense fallback={fallback}>{form}</Suspense>}
            </GridColumn>
            <GridColumn col={3}>
                <StickyContainer>
                    <DownloadGuide>
                        <Info />
                        <Text
                            variant="subheading"
                            as="a"
                            href="https://simplonline-v3-prod.s3.eu-west-3.amazonaws.com/media/guide/Simplon+-+Guide+de+la+machine+a%CC%80+briefs.pdf"
                            target="_blank"
                        >
                            {t("component.CreateBriefLayout.downloadGuide")}
                        </Text>
                    </DownloadGuide>
                    {children}
                </StickyContainer>
            </GridColumn>
        </Grid>
    );
};

export default styled(CreateBriefLayout)`
    ${CreateBriefStepsAside} {
        margin-top: ${({ theme }) => theme.spacing[5]};
    }
`;
