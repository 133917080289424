import { HandlerStatus, addNewBlockAt, getSelectedBlock, pasteText, splitBlockFromCurrentSelection } from "./utils";

const continuousBlocks = ["p", "unstyled"];

const defaultBehaviourPlugin = () => {
    return {
        handleReturn: (_event, editorState, { setEditorState }) => {
            const block = getSelectedBlock(editorState);
            const blockKey = block.getKey();
            const blockType = block.getType();
            const isContinuous = continuousBlocks.includes(blockType);
            if (isContinuous) {
                // Try to split a block from the cursor position
                const newEditorState = splitBlockFromCurrentSelection(editorState);

                // If it is successful
                if (newEditorState !== editorState) {
                    // Update editor state with new content
                    setEditorState(newEditorState);
                    return HandlerStatus.HANDLED;
                }
            }

            const newBlockType = isContinuous ? blockType : "unstyled";

            setEditorState(
                addNewBlockAt(editorState, blockKey, {
                    type: newBlockType,
                }),
            );
            return HandlerStatus.HANDLED;
        },
        handlePastedText(text, _html, editorState, { setEditorState }) {
            setEditorState(pasteText(editorState, text));

            return HandlerStatus.HANDLED;
        },
    };
};

export default defaultBehaviourPlugin;
