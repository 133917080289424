/* eslint-disable react/display-name */
import { EditorState, Modifier } from "draft-js";
import createLinkPlugin from "draft-js-anchor-plugin";
import createImagePlugin from "draft-js-image-plugin";
import createInlineToolbarPlugin from "draft-js-inline-toolbar-plugin";
import Editor from "draft-js-plugins-editor";
import { Map } from "immutable";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useId } from "react-aria";
import { useTranslation } from "react-i18next";
import { Text } from "src/uikit";
import styled from "styled-components";

import useLocale from "../../../hooks/useLocale";
import { Blockquote, OrderedList, UnorderedList } from "../../typography";
import EditorStylesheet from "./EditorStylesheet";
import Image from "./Image";
import Link from "./Link";
import createCodePlugin from "./plugins/code";
import createDefaultBehaviourPlugin from "./plugins/defaultBehaviour";
import createListPlugin from "./plugins/lists";
import Toolbar, { buttonStyles, toolbarStyles } from "./Toolbar";

const useControlledInput = (input, defaultValue) => {
    const { value, onChange } = input || {};

    const [internalValue, setInternalValue] = useState(defaultValue);

    const isControlled = !!onChange;

    const handleChange = useCallback(
        editorState => {
            return (isControlled ? onChange : setInternalValue)(editorState);
        },
        [isControlled, onChange, setInternalValue],
    );

    return [isControlled ? value || defaultValue : internalValue, handleChange];
};

const Wysiwyg = props => {
    const { value, className, onChange, onBlur, placeholder, ...restProps } = props;
    const labelId = useId();

    const [t] = useTranslation();
    const editor = useRef(null);

    const handleClick = useCallback(() => {
        if (editor && editor.current) {
            editor.current.focus();
        }
    }, [editor]);

    const [outputValue, handleChange] = useControlledInput({ value, onChange }, EditorState.createEmpty());

    const [readOnly, setReadOnly] = useState(false);

    const inlineToolbarPlugin = useMemo(
        () =>
            createInlineToolbarPlugin({
                theme: {
                    buttonStyles,
                    toolbarStyles,
                },
            }),
        [],
    );

    const linkPlugin = useMemo(
        () =>
            createLinkPlugin({
                Link,
                theme: {
                    input: "sol-input",
                },
                placeholder: t("inputs.Wysiwyg.linkPlaceholder"),
            }),
        [t],
    );

    const imagePlugin = useMemo(
        () =>
            createImagePlugin({
                imageComponent: Image,
            }),
        [],
    );

    const { InlineToolbar } = inlineToolbarPlugin;

    const LinkButton = useCallback(
        linkProps => {
            const { getEditorState, setEditorState } = linkProps;
            const store = { getEditorState, setEditorState };
            return <linkPlugin.LinkButton {...linkProps} store={store} />;
        },
        [linkPlugin],
    );

    const plugins = useMemo(
        () => [
            createListPlugin({
                maxDepth: 2,
            }),
            inlineToolbarPlugin,
            linkPlugin,
            imagePlugin,
            createCodePlugin({ setReadOnly, readOnly }),
            createDefaultBehaviourPlugin(),
        ],
        [inlineToolbarPlugin, linkPlugin, imagePlugin, readOnly, setReadOnly],
    );

    const blockRenderMap = useMemo(() => {
        return new Map({
            "header-one": {
                element: props => <Text {...props} variant="h1" />,
            },
            "header-two": {
                element: props => <Text {...props} variant="h2" />,
            },
            "header-three": {
                element: props => <Text {...props} variant="h3" />,
            },
            blockquote: {
                element: Blockquote,
            },
            "ordered-list-item": {
                element: "li",
                wrapper: <OrderedList />,
            },
            "unordered-list-item": {
                element: "li",
                wrapper: <UnorderedList />,
            },
            unstyled: {
                element: props => <Text {...props} preserve />,
            },
        });
    }, []);

    const renderToolbar = useCallback(
        externalProps => <Toolbar {...externalProps} LinkButton={LinkButton} addImage={imagePlugin.addImage} />,
        [LinkButton, imagePlugin],
    );

    const [locale] = useLocale();

    const handleReturn = e => {
        if (!e.shiftKey) {
            const currentContent = outputValue.getCurrentContent();
            const selectionState = outputValue.getSelection();
            const newContentState = Modifier.splitBlock(currentContent, selectionState);
            const newEditorState = EditorState.push(outputValue, newContentState, "split-block");
            handleChange(newEditorState);
            return "handled";
        }
        return "not-handled";
    };

    return (
        <>
            <span className="sr-only" id={labelId}>
                {t(`component.AddBriefDescriptionForm.${restProps.name}.label`)}
            </span>
            <EditorStylesheet {...restProps} className={className} onClick={handleClick}>
                <Editor
                    key={locale}
                    ref={editor}
                    readOnly={readOnly}
                    editorState={outputValue}
                    onChange={handleChange}
                    plugins={plugins}
                    defaultBlockRenderMap
                    blockRenderMap={blockRenderMap}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    handleReturn={handleReturn}
                    ariaLabelledBy={labelId}
                />

                <InlineToolbar>{renderToolbar}</InlineToolbar>
            </EditorStylesheet>
        </>
    );
};

export default styled(Wysiwyg)`
    display: flex;
    align-items: flex-start;
    min-height: 122px;
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    padding: ${({ theme }) => theme.spacing[4]};

    border: 1px solid ${({ theme, isError }) => (isError ? theme.palette.red.base : theme.palette.grey.lighter)};

    box-shadow: 0px 0px 0px 0px
        ${({ theme, isError }) =>
            isError ? `${theme.palette.red.base}, inset 0 0 0 1px ${theme.palette.red.base}` : "transparent"};

    transition: box-shadow 0.2s;
    will-change: box-shadow;

    &:focus-within {
        border: 1px solid ${({ theme, isError }) => theme.palette[isError ? "red" : "purple"].base};
        box-shadow: 0px 0px 0px 4px ${({ theme, isError }) => theme.palette[isError ? "red" : "purple"].light},
            inset 0 0 0 1px ${({ theme, isError }) => theme.palette[isError ? "red" : "purple"].base};
    }
`;
