import clsx from "clsx";
import styled from "styled-components";

const Image = styled(props => {
    const { block, className, theme = {}, ...otherProps } = props;
    // leveraging destructuring to omit certain properties from props
    const {
        blockProps,
        customStyleMap,
        customStyleFn,
        decorator,
        forceSelection,
        offsetKey,
        selection,
        tree,
        contentState,
        blockStyleFn,
        ...elementProps
    } = otherProps;
    const combinedClassName = clsx(theme.image, className);
    const { src } = contentState.getEntity(block.getEntityAt(0)).getData();
    return (
        <div className={combinedClassName}>
            <img {...elementProps} src={src} role="presentation" />
        </div>
    );
})`
    width: 100%;
    text-align: center;

    & > img {
        max-width: 100%;
        display: block;
    }
`;

export default Image;
