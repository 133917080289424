import { EditorState } from "draft-js";
import { stateToMarkdown } from "draft-js-export-markdown";

const regex = /[a-zA-Z]/g;

export const getStateToMarkdown = (value?: EditorState) => {
    if (!value) {
        return undefined;
    }

    const markdown = stateToMarkdown(value.getCurrentContent());

    return markdown?.search(regex) >= 0 ? markdown?.trim() : undefined;
};
