import { isArray } from "lodash";
import { useRouter } from "next/router";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SkillLevelsPicker from "src/components/shared/pickers/SkillLevelsPicker";
import styled from "styled-components";

import { Arrow } from "@sol/icons";
import { ISkillLevelItem, useSkills } from "@sol/sdk";
import { Button, InputDecorator } from "@sol/uikit";

import { CreateBriefSkillsStepPayload } from "../create/CreateBriefFormsManager";

const ActionsBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacing[6]};
    button:last-child {
        margin-left: auto;
    }
`;

const Card = styled.div`
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    background-color: ${({ theme }) => theme.palette.white.base};
`;

type Props = {
    onStepSubmit: (skillLevels: CreateBriefSkillsStepPayload["skillLevels"]) => void;
    onBackClick: () => void;
    professionalSituationId?: string;
    className?: string;
    isLoading?: boolean;
    isReadOnly?: boolean;
    briefSkills?: string[];
    isEdit: boolean;
};

const AddBriefSkillLevelsForm = ({
    onStepSubmit,
    onBackClick,
    professionalSituationId,
    className,
    isLoading,
    isReadOnly,
    briefSkills,
    isEdit,
}: Props) => {
    const [t] = useTranslation();
    const { control, handleSubmit, formState } = useFormContext();
    const router = useRouter();
    // Recupérer les compétences liées au brief
    // Empecher la modification des niveaux de compétences si brief corrigé
    const { data: skills } = useSkills({
        filters: professionalSituationId
            ? { professionalSituations: professionalSituationId }
            : { skills: briefSkills },
        suspense: true,
        enabled: !isLoading,
    });

    const onSubmit = ({ skillLevels }: CreateBriefSkillsStepPayload) => {
        onStepSubmit(skillLevels);
    };

    return (
        <form className={className} onSubmit={handleSubmit(onSubmit)}>
            <Card>
                <Controller
                    control={control}
                    name="skillLevels"
                    render={({ field: { name, onChange, value }, fieldState: { invalid, error } }) => {
                        const handleChange = (skillLevelItems: ISkillLevelItem[]) => {
                            onChange(
                                skillLevelItems.map(({ evalCriteria, actions, ...rest }) => {
                                    const skill = skills?.["hydra:member"].find(({ "@id": id }) => id === rest.skill);
                                    return { ...rest, skillTitle: skill?.shortTitle };
                                }),
                            );
                        };

                        return (
                            <InputDecorator required htmlFor={name} error={isArray(error) ? undefined : error}>
                                {({ errorId }) => (
                                    <SkillLevelsPicker
                                        skills={isLoading ? undefined : skills?.["hydra:member"]}
                                        value={value}
                                        onChange={handleChange}
                                        aria-describedby={errorId}
                                        aria-invalid={invalid}
                                        isReadOnly={isReadOnly}
                                        tabsVariant="secondary"
                                        tabButtonVariant="secondary"
                                    />
                                )}
                            </InputDecorator>
                        );
                    }}
                />
            </Card>
            {!isLoading && (
                <ActionsBox>
                    {!isReadOnly && (
                        <Button
                            outlined
                            variant="primary"
                            type="button"
                            onClick={isEdit ? () => router.back() : onBackClick}
                        >
                            <Arrow direction="left" />
                            {t("component.shared.AddBriefSkillLevelsView.back")}
                        </Button>
                    )}
                    <Button
                        type="submit"
                        filled
                        variant="primary"
                        disabled={formState.isSubmitting}
                        data-cy="submit-brief-skill-levels-form"
                    >
                        {t("component.shared.AddBriefSkillLevelsView.nextStep")}
                        <Arrow direction="right" />
                    </Button>
                </ActionsBox>
            )}
        </form>
    );
};

export default styled(AddBriefSkillLevelsForm)`
    ${SkillLevelsPicker} {
        border: 0;
    }
`;
