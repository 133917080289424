import { EditorState, RichUtils } from "draft-js";

import { HandlerStatus, addNewBlockAt, getSelectedBlock } from "./utils";

const updateBlockDepth = (editorState, block, direction) => {
    const blockKey = block.getKey();
    const depth = block.getDepth();
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();

    const newBlock = block.set("depth", depth + (direction === "down" ? -1 : 1));
    const newBlockMap = blockMap.set(blockKey, newBlock);

    return EditorState.push(editorState, contentState.merge({ blockMap: newBlockMap }), "adjust-depth");
};

const isListItem = block => ["unordered-list-item", "ordered-list-item"].includes(block.getType());

const hasEmptyText = block => {
    const text = block.getText();
    return text.length === 0;
};

const listPlugin = (options = { maxDepth: 4 }) => ({
    handleReturn: (_event, editorState, { setEditorState }) => {
        const block = getSelectedBlock(editorState);

        if (isListItem(block)) {
            const depth = block.getDepth();
            if (hasEmptyText(block)) {
                if (depth > 0) {
                    setEditorState(updateBlockDepth(editorState, block, "down"));
                } else {
                    setEditorState(
                        EditorState.push(
                            editorState,
                            RichUtils.tryToRemoveBlockStyle(editorState),
                            "change-block-type",
                        ),
                    );
                }
            } else {
                setEditorState(
                    addNewBlockAt(editorState, block.getKey(), {
                        type: block.getType(),
                        depth,
                    }),
                );
            }

            return HandlerStatus.HANDLED;
        }

        return HandlerStatus.NOT_HANDLED;
    },
    onTab: (event, { setEditorState, getEditorState }) => {
        const { maxDepth } = options;
        const editorState = getEditorState();
        const block = getSelectedBlock(editorState);
        if (isListItem(block)) {
            setEditorState(RichUtils.onTab(event, editorState, maxDepth));

            return HandlerStatus.HANDLED;
        }

        return HandlerStatus.NOT_HANDLED;
    },
});

export default listPlugin;
