import { mix } from "polished";
import styled from "styled-components";

const Code = styled.code`
    ${({ theme }) => theme.typography.code};
    color: ${({ theme }) => theme.palette.black.base};

    background-color: ${({ theme }) => mix(0.9, theme.palette.white.base, theme.palette.purple.base)};
    padding: ${({ theme }) => theme.spacing[4]};
    border-radius: ${({ theme }) => theme.shape.borderRadius.small};
`;

Code.displayName = "Code";

export default Code;
