import { yupResolver } from "@hookform/resolvers/yup";
import { isArray } from "lodash";
import React, { MutableRefObject, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LanguageSelect from "src/components/shared/LanguageSelect";
import SkillsPicker from "src/components/shared/pickers/SkillsPicker";
import { ProfessionalSituationPickerContext } from "src/components/shared/ProfessionalSituations/ProfessionalSituationsPicker";
import styled from "styled-components";

import { useActiveClassroom } from "@sol/classrooms";
import { Arrow, Loading } from "@sol/icons";
import { getResourceUuid, useLanguages } from "@sol/sdk";
import { Button, Grid, GridColumn, InputDecorator, TextArea, TextInput } from "@sol/uikit";

import { Text } from "../../../../uikit";
import {
    buildCreateProfessionalSituationSchema,
    PROFESSIONAL_SITUATION_VALIDATION_RULES as validation,
} from "./validations";

const Form = styled.form`
    padding: ${({ theme }) => theme.spacing[7]};
    margin-top: ${({ theme }) => theme.spacing[6]};
    background-color: ${({ theme }) => theme.palette.white.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    width: 100%;

    ${InputDecorator} + ${InputDecorator},
    > ${Grid} > ${GridColumn} + ${GridColumn} {
        margin-top: ${({ theme }) => theme.spacing[9]};
    }
`;

const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacing[9]};
`;

const MandatoryLabel = styled(Text)`
    --text-color: ${({ theme }) => theme.palette.red.base};
    display: inline-flex;
    text-align: right;
    margin-bottom: ${({ theme }) => theme.spacing[3]};
`;

type FormValues = {
    title: string;
    language: string;
    targetedDomain: string;
    problematic: string;
    description: string;
    skills: string[];
};

const initialValues: FormValues = {
    title: "",
    language: "",
    targetedDomain: "",
    problematic: "",
    description: "",
    skills: [],
};

type Props = {
    defaultValues?: Partial<FormValues>;
    isLoading?: boolean;
    onSubmit: (payload: FormValues) => void;
    onBackClick: () => void;
    className?: string;
    isEdit?: boolean;
    briefSkills?: string[];
    briefLanguage?: string;
    isMutationLoading?: boolean;
    context?: ProfessionalSituationPickerContext;
    submitButtonRef?: MutableRefObject<null>;
};

const CreateProfessionalSituationForm = ({
    defaultValues,
    isLoading: isPending = false,
    isMutationLoading = false,
    onBackClick,
    onSubmit,
    className,
    isEdit,
    briefSkills,
    briefLanguage,
    context,
    submitButtonRef,
}: Props) => {
    const [t] = useTranslation();
    const { activeClassroom } = useActiveClassroom();
    const { data: languages, isLoading: areLanguagesLoading } = useLanguages({});
    const languagesOptions = languages?.["hydra:member"]?.map(language => ({
        label: language.title,
        value: language["@id"],
        locale: language.locale,
    }));
    const getDefaultLanguage = () => {
        if (briefLanguage) {
            return briefLanguage;
        }

        if (defaultValues && defaultValues.language && languagesOptions) {
            const { language } = defaultValues;
            const defaultLanguage = languagesOptions?.filter(l => l.value === language);
            return defaultLanguage[0].value;
        }

        return "";
    };

    const isDefaultBriefLanguageDefined = !!briefLanguage;
    const { PEDAGOGICAL_SCENARIO } = ProfessionalSituationPickerContext;
    const formDefaultValues = useMemo(
        () => ({
            ...initialValues,
            ...defaultValues,
            language: getDefaultLanguage(),
        }),
        [defaultValues, briefLanguage],
    );

    const {
        control,
        handleSubmit,
        formState,
        reset: resetForm,
        setError,
        clearErrors,
    } = useForm({
        defaultValues: formDefaultValues,
        mode: isEdit ? "onSubmit" : "onChange",
        resolver: yupResolver(buildCreateProfessionalSituationSchema()),
    });

    const [isDisable, setIsDisable] = useState(false);

    useEffect(() => {
        if (formDefaultValues) {
            resetForm({
                ...initialValues,
                ...defaultValues,
            });
        }
    }, [formDefaultValues]);

    const isLoading = isPending || areLanguagesLoading;
    const isBriefSkillCompatible = (skills: string[]) => briefSkills?.every(skill => skills.includes(skill));

    const customHandleSubmit = (data: FormValues) => {
        if (isEdit) {
            const { skills } = data;
            !isBriefSkillCompatible(skills)
                ? setError("skills", {
                      type: "invalid",
                      message: t("component.CreateProfessionalSituationForm.skillsIncompatible"),
                  })
                : onSubmit(data);
        } else {
            onSubmit(data);
        }
    };

    return (
        <>
            <Form
                onSubmit={handleSubmit(customHandleSubmit)}
                className={className}
                data-cy="add-professional-situation-form"
            >
                <MandatoryLabel variant="subheading">
                    {t("component.CreateProfessionalSituationForm.mandatoryFields")}
                </MandatoryLabel>
                <Grid>
                    <GridColumn col={10}>
                        <Controller
                            control={control}
                            name="title"
                            render={({ field: { name, value, ...field }, fieldState: { invalid, error } }) => (
                                <InputDecorator
                                    required
                                    htmlFor={name}
                                    label={t("component.CreateProfessionalSituationForm.titleLabel")}
                                    helper={t("component.CreateProfessionalSituationForm.titleHelper", {
                                        count: value.length,
                                        max: validation.title.max,
                                    })}
                                    error={error}
                                >
                                    {({ errorId }) => (
                                        <TextInput
                                            value={value}
                                            {...field}
                                            id={name}
                                            data-cy="professional-situation-title"
                                            placeholder={t(
                                                "component.CreateProfessionalSituationForm.titlePlaceholder",
                                            )}
                                            aria-describedby={errorId}
                                            aria-invalid={invalid}
                                            variant={invalid ? "error" : "default"}
                                            disabled={isLoading}
                                        />
                                    )}
                                </InputDecorator>
                            )}
                        />
                        <Controller
                            control={control}
                            name="language"
                            render={({ field: { name, value, onChange }, fieldState: { invalid, error } }) => {
                                return (
                                    <InputDecorator
                                        required
                                        htmlFor={name}
                                        label={t("component.CreateProfessionalSituationForm.languageLabel")}
                                        error={error}
                                    >
                                        {({ errorId }) => (
                                            <LanguageSelect
                                                disabled={!!isEdit && isDefaultBriefLanguageDefined}
                                                isLoading={areLanguagesLoading || isPending}
                                                aria-describedby={errorId}
                                                aria-invalid={invalid}
                                                defaultValue={value}
                                                label={t(
                                                    "component.CreateProfessionalSituationForm.languageSelectLabel",
                                                )}
                                                onSelect={key => {
                                                    onChange(key);
                                                }}
                                                className="w-fit"
                                            />
                                        )}
                                    </InputDecorator>
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name="targetedDomain"
                            render={({ field: { name, value, ...field }, fieldState: { invalid, error } }) => (
                                <InputDecorator
                                    required
                                    htmlFor={name}
                                    label={t("component.CreateProfessionalSituationForm.targetedDomainLabel")}
                                    helper={t("component.CreateProfessionalSituationForm.targetedDomainHelper", {
                                        count: value.length,
                                        max: validation.targetedDomain.max,
                                    })}
                                    error={error}
                                >
                                    {({ errorId }) => (
                                        <TextInput
                                            {...field}
                                            value={value}
                                            id={name}
                                            data-cy="professional-situation-targeted-domain"
                                            placeholder={t(
                                                "component.CreateProfessionalSituationForm.targetedDomainPlaceholder",
                                            )}
                                            aria-describedby={errorId}
                                            aria-invalid={invalid}
                                            variant={invalid ? "error" : "default"}
                                            disabled={isLoading}
                                        />
                                    )}
                                </InputDecorator>
                            )}
                        />
                    </GridColumn>
                    <GridColumn col={12}>
                        <Controller
                            control={control}
                            name="problematic"
                            render={({ field: { name, value, ...rest }, fieldState: { invalid, error } }) => {
                                return (
                                    <InputDecorator
                                        required
                                        htmlFor={name}
                                        label={t("component.CreateProfessionalSituationForm.problematicLabel")}
                                        helper={t("component.CreateProfessionalSituationForm.problematicHelper", {
                                            count: value.length,
                                            max: validation.problematic.max,
                                        })}
                                        error={error}
                                    >
                                        {({ errorId }) => (
                                            <TextArea
                                                {...rest}
                                                value={value}
                                                minRows={4}
                                                id={name}
                                                data-cy="professional-situation-problematic"
                                                aria-describedby={errorId}
                                                aria-invalid={invalid}
                                                variant={invalid ? "error" : "default"}
                                                placeholder={t(
                                                    "component.CreateProfessionalSituationForm.problematicPlaceholder",
                                                )}
                                                disabled={isLoading}
                                            />
                                        )}
                                    </InputDecorator>
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name="description"
                            render={({ field: { name, value, ...rest }, fieldState: { invalid, error } }) => {
                                return (
                                    <InputDecorator
                                        required
                                        htmlFor={name}
                                        label={t("component.CreateProfessionalSituationForm.descriptionLabel")}
                                        helper={t("component.CreateProfessionalSituationForm.descriptionLabelHelper", {
                                            count: value.length,
                                            max: validation.description.max,
                                        })}
                                        error={error}
                                    >
                                        {({ errorId }) => (
                                            <TextArea
                                                {...rest}
                                                value={value}
                                                minRows={4}
                                                id={name}
                                                data-cy="professional-situation-description"
                                                aria-describedby={errorId}
                                                aria-invalid={invalid}
                                                variant={invalid ? "error" : "default"}
                                                placeholder={t(
                                                    "component.CreateProfessionalSituationForm.descriptionLabelPlaceholder",
                                                )}
                                                disabled={isLoading}
                                            />
                                        )}
                                    </InputDecorator>
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name="skills"
                            render={({ field: { name, onChange, value }, fieldState: { invalid, error } }) => {
                                const isSkillsCompatible = (values: string[]) => {
                                    if (isEdit && !isBriefSkillCompatible(values)) {
                                        setIsDisable(true);
                                        setError("skills", {
                                            message: t("component.CreateProfessionalSituationForm.skillsIncompatible"),
                                        });
                                    } else {
                                        setIsDisable(false);
                                        clearErrors("skills");
                                    }
                                    onChange(values);
                                };

                                return (
                                    <InputDecorator
                                        required
                                        htmlFor={name}
                                        label={t("component.CreateProfessionalSituationForm.skillsLabel")}
                                        helper={t("component.CreateProfessionalSituationForm.skillsHelper", {
                                            count: value.length,
                                        })}
                                        error={isArray(error) ? undefined : error}
                                    >
                                        {({ errorId }) => (
                                            <SkillsPicker
                                                value={value}
                                                onChange={e => isSkillsCompatible(e)}
                                                aria-describedby={errorId}
                                                aria-invalid={invalid}
                                                classroom={
                                                    context === PEDAGOGICAL_SCENARIO && activeClassroom
                                                        ? getResourceUuid(activeClassroom)
                                                        : undefined
                                                }
                                            />
                                        )}
                                    </InputDecorator>
                                );
                            }}
                        />
                        <Actions>
                            <Button variant="primary" type="button" onClick={onBackClick} disabled={isMutationLoading}>
                                <Arrow direction="left" />
                                {t("component.CreateProfessionalSituationForm.previousStep")}
                            </Button>

                            <Button
                                ref={submitButtonRef}
                                filled
                                variant="primary"
                                type="submit"
                                disabled={isLoading || formState.isSubmitting || isDisable || isMutationLoading}
                                loading={isLoading || isMutationLoading}
                                data-cy="create-professional-situation"
                            >
                                {t(
                                    `component.CreateProfessionalSituationForm.${
                                        context === PEDAGOGICAL_SCENARIO ? "submitPedagogicalScenarioContext" : "submit"
                                    }`,
                                )}
                                {(isLoading || isMutationLoading) && <Loading />}
                            </Button>
                        </Actions>
                    </GridColumn>
                </Grid>
            </Form>
        </>
    );
};

export default styled(CreateProfessionalSituationForm)``;
