import { transparentize } from "polished";
import styled from "styled-components";

import { Theme } from "../../../uikit/theme";
import { buttonStyles, toolbarStyles } from "./Toolbar";

const primaryColor = ({ theme }: { theme: Theme }) => theme.palette.purple.base;

const EditorStylesheet = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    &,
    .DraftEditor-root {
        width: 100%;
    }

    .DraftEditor-root {
        box-sizing: border-box;
        position: relative;
        display: flex;
        align-items: start;
    }

    .public-DraftEditorPlaceholder-root {
        position: absolute;
        user-select: none;
        z-index: ${({ theme }) => theme.zIndex.element};
        cursor: text;

        ${({ theme }) => theme.typography.p}
        color: ${({ theme }) => theme.palette.grey.base};

        max-width: 100%;
    }

    .DraftEditor-editorContainer {
        width: 100%;
        position: relative;
    }

    height: 100%;
    box-sizing: border-box;

    .${buttonStyles.buttonWrapper} {
        display: inline-block;
    }

    .${buttonStyles.button} {
        height: 100%;
        background: transparent;
        border: 0;
        display: flex;
        align-items: center;
        padding: 0 ${({ theme }) => theme.spacing[3]};
        cursor: pointer;

        .icon-fillcolor,
        circle:not([fill="none"]):not(.fillIgnore),
        path:not([fill="none"]):not(.fillIgnore) {
            fill: ${({ theme }) => theme.palette.white.base};
        }

        .icon-strokecolor,
        line {
            stroke: ${({ theme }) => theme.palette.white.base};
        }

        &:hover,
        &:focus {
            outline: 0; /* reset for :focus */
        }

        &:hover {
            .icon-fillcolor,
            circle:not([fill="none"]):not(.fillIgnore),
            path:not([fill="none"]):not(.fillIgnore) {
                fill: ${({ theme }) => theme.palette.grey.lighter};
            }

            .icon-strokecolor,
            line {
                stroke: ${({ theme }) => theme.palette.grey.lighter};
            }
        }
    }

    .${buttonStyles.active} {
        .icon-fillcolor,
        circle:not([fill="none"]):not(.fillIgnore),
        path:not([fill="none"]):not(.fillIgnore) {
            fill: ${({ theme }) => theme.palette.yellow.base};
        }

        .icon-strokecolor,
        line {
            stroke: ${({ theme }) => theme.palette.yellow.base};
        }
    }

    .draftJsToolbar__separator__3U7qt {
        background: ${({ theme }) => theme.palette.white.base};
        width: 1px;
        height: 24px;
        margin: ${({ theme }) => theme.spacing[3]};
        line-height: 4rem;
    }

    .sol-input {
        width: 250px;
        background: ${primaryColor};
        border: none;
        padding: 0 ${({ theme }) => theme.spacing[3]};
        box-sizing: border-box;
        color: ${({ theme }) => theme.palette.white.base};
        &::placeholder {
            color: ${({ theme }) => transparentize(0.3, theme.palette.white.base)};
        }
    }

    .sol-anchor {
        text-decoration: underline;
        color: ${({ theme }) => theme.palette.purple.base};
    }

    .${toolbarStyles.toolbar} {
        left: 50%;
        transform: translate(-50%) scale(0);
        position: absolute;
        background: ${primaryColor};
        z-index: ${({ theme }) => theme.zIndex.navigation};
        box-sizing: border-box;
        border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        padding: 0 ${({ theme }) => theme.spacing[3]};

        display: flex;
        height: 40px;

        &:after,
        &:before {
            top: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &:after {
            border-color: rgba(255, 255, 255, 0);
            border-top-color: ${primaryColor};
            border-width: 8px;
            margin-left: -${({ theme }) => theme.spacing[3]};
        }
        &:before {
            border-color: rgba(221, 221, 221, 0);
            border-top-color: #ddd;
            border-width: 6px;
            margin-left: -6px;
        }
    }

    .draftJsFocusPlugin__unfocused__1Wvrs:hover {
        cursor: default;
        border-radius: 2px;
        box-shadow: 0 0 0 3px #d2e3f7;
    }

    .draftJsFocusPlugin__focused__3Mksn {
        cursor: default;
        border-radius: 2px;
        box-shadow: 0 0 0 3px #accef7;
    }

    .public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
        margin-left: 1.5em;
    }
    .public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
        margin-right: 1.5em;
    }
    .public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
        margin-left: 3em;
    }
    .public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
        margin-right: 3em;
    }
    .public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
        margin-left: 4.5em;
    }
    .public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
        margin-right: 4.5em;
    }
    .public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
        margin-left: 6em;
    }
    .public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
        margin-right: 6em;
    }
    .public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
        margin-left: 7.5em;
    }
    .public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
        margin-right: 7.5em;
    }
    .public-DraftStyleDefault-unorderedListItem {
        list-style-type: square;
        position: relative;
    }
    .public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
        list-style-type: disc;
    }
    .public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
        list-style-type: circle;
    }
    .public-DraftStyleDefault-orderedListItem {
        list-style-type: none;
        position: relative;
    }
    .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
        left: -36px;
        position: absolute;
        text-align: right;
        width: 30px;
    }
    .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
        position: absolute;
        right: -36px;
        text-align: left;
        width: 30px;
    }
    .public-DraftStyleDefault-orderedListItem:before {
        content: counter(ol0) ". ";
        counter-increment: ol0;
    }
    .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
        content: counter(ol1, lower-alpha) ". ";
        counter-increment: ol1;
    }
    .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
        content: counter(ol2, lower-roman) ". ";
        counter-increment: ol2;
    }
    .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
        content: counter(ol3) ". ";
        counter-increment: ol3;
    }
    .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
        content: counter(ol4, lower-alpha) ". ";
        counter-increment: ol4;
    }
    .public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
        counter-reset: ol0;
    }
    .public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
        counter-reset: ol1;
    }
    .public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
        counter-reset: ol2;
    }
    .public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
        counter-reset: ol3;
    }
    .public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
        counter-reset: ol4;
    }
`;

export default EditorStylesheet;
