import { Text } from "src/uikit";
import styled from "styled-components";

const Blockquote = styled(Text)`
    border-left: 2px solid ${({ theme }) => theme.palette.purple.base};
    padding-left: ${({ theme }) => theme.spacing[6]};
`;

Blockquote.defaultProps = {
    ...Text.defaultProps,
    as: "blockquote",
};

Blockquote.displayName = "Blockquote";

export default Blockquote;
