const LANGUAGES = [
    {
        name: "HTML",
        value: "htmlmixed",
    },
    {
        name: "CSS",
        value: "css",
    },
    {
        name: "SASS",
        value: "sass",
    },
    {
        name: "Javascript",
        value: "javascript",
    },
    {
        name: "JSX",
        value: "jsx",
    },
    {
        name: "SQL",
        value: "sql",
    },
    {
        name: "PHP",
        value: "php",
    },
    {
        name: "Java",
        value: "text/x-java",
    },
    {
        name: "C#",
        value: "text/x-csharp",
    },
    {
        name: "Python",
        value: "python",
    },
    {
        name: "ruby",
        value: "ruby",
    },
    {
        name: "Shell",
        value: "shell",
    },
    {
        name: "Powershell",
        value: "powershell",
    },
    {
        name: "R",
        value: "r",
    },
    {
        name: "Go",
        value: "go",
    },
    {
        name: "Swift",
        value: "swift",
    },
    {
        name: "Objective-C",
        value: "text/x-objectivec",
    },
];

export default LANGUAGES;
