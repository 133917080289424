import React from "react";

import { Anchor } from "../../typography";

const Link = ({ children, entityKey, getEditorState, ...rest }) => {
    const entity = getEditorState().getCurrentContent().getEntity(entityKey);
    const entityData = entity ? entity.get("data") : undefined;
    const href = (entityData && entityData.url) || undefined;

    return (
        <Anchor {...rest} title={href} href={href} rel="noopener noreferrer">
            {children}
        </Anchor>
    );
};

export default Link;
