import { Separator } from "draft-js-inline-toolbar-plugin";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Bold, H1, H2, H3, Italic, Link, OrderedList, Underline, UnorderedList, StrikeThrough } from "@sol/icons";

import { BlockquoteIcon, CodeBlockIcon, ImageIcon } from "../../icons";
import BlockTypeButton from "./BlockTypeButton";
import InlineStyleButton from "./InlineStyleButton";
import AddImageButton from "./plugins/image/AddImageButton";

export const buttonStyles = {
    buttonWrapper: "sol-wysiwyg-buttonWrapper",
    button: "sol-wysiwyg-button",
    active: "sol-wysiwyg-button-active",
    input: "sol-input",
};

export const toolbarStyles = {
    toolbar: "sol-wysiwyg-toolbar",
};

const WysiwygToolbar = ({ LinkButton, addImage, className, ...externalProps }) => {
    const [t] = useTranslation();

    return (
        <div className={className}>
            <BlockTypeButton {...externalProps} blockType="header-one">
                <H1 />
            </BlockTypeButton>
            <BlockTypeButton {...externalProps} blockType="header-two">
                <H2 />
            </BlockTypeButton>
            <BlockTypeButton {...externalProps} blockType="header-three">
                <H3 />
            </BlockTypeButton>
            <Separator {...externalProps} />
            <AddImageButton
                {...externalProps}
                placeholder={t("inputs.SOLWysiwyg.imageLinkPlaceholder")}
                onSubmit={(value, { getEditorState, setEditorState }) => {
                    const editorState = getEditorState();

                    setEditorState(addImage(editorState, value));
                }}
            >
                <ImageIcon />
            </AddImageButton>
            <LinkButton {...externalProps}>
                <Link />
            </LinkButton>
            <BlockTypeButton {...externalProps} blockType="blockquote">
                <BlockquoteIcon />
            </BlockTypeButton>
            <BlockTypeButton {...externalProps} blockType="code-block">
                <CodeBlockIcon />
            </BlockTypeButton>
            <Separator {...externalProps} />
            <InlineStyleButton {...externalProps} inlineStyle="BOLD">
                <Bold />
            </InlineStyleButton>
            <InlineStyleButton {...externalProps} inlineStyle="ITALIC">
                <Italic />
            </InlineStyleButton>
            <InlineStyleButton {...externalProps} inlineStyle="UNDERLINE">
                <Underline />
            </InlineStyleButton>
            <InlineStyleButton {...externalProps} inlineStyle="STRIKETHROUGH">
                <StrikeThrough />
            </InlineStyleButton>
            <Separator {...externalProps} />
            <BlockTypeButton {...externalProps} blockType="unordered-list-item">
                <UnorderedList />
            </BlockTypeButton>
            <BlockTypeButton {...externalProps} blockType="ordered-list-item">
                <OrderedList />
            </BlockTypeButton>
        </div>
    );
};

export default styled(WysiwygToolbar)`
    display: flex;

    ${UnorderedList}, ${OrderedList} {
        --icon-color: white;
    }
`;
