import { EditorState, Modifier, SelectionState } from "draft-js";
import flow from "lodash/flow";

import { HandlerStatus, addNewBlockAt, getSelectedBlock, pasteText } from "../utils";
import CodeBlock from "./CodeBlock";
import { insertIndent, insertNewLine } from "./utils";

const isCodeBlock = block => block.getType() === "code-block";

const codePlugin = (options = {}) => {
    const { readOnly, setReadOnly } = options;
    return {
        blockRendererFn: (block, { getEditorState, setEditorState }) => {
            if (isCodeBlock(block)) {
                return {
                    component: CodeBlock,
                    editable: false,
                    props: {
                        getEditorState,
                        setEditorState,
                        readOnly,
                        setReadOnly,
                    },
                };
            }

            return undefined;
        },

        handleReturn: (_event, editorState, { setEditorState }) => {
            const block = getSelectedBlock(editorState);
            if (isCodeBlock(block)) {
                const selection = editorState.getSelection();

                // check if we are in the last line and got 2 previous breaklines
                if (block.getLength() === selection.getEndOffset()) {
                    const text = block.getText();

                    const trimEndRegex = /\s+$/;
                    const trimMatch = text.match(trimEndRegex);

                    if (trimMatch && trimMatch.length > 0 && (trimMatch[0].match(/\r?\n/g) || []).length > 1) {
                        const blockKey = block.getKey();
                        const trimmedText = text.replace(trimEndRegex, "");

                        const contentState = editorState.getCurrentContent();

                        const newSelection = new SelectionState({
                            anchorKey: blockKey,
                            anchorOffset: trimmedText.length,
                            focusKey: blockKey,
                            focusOffset: text.length,
                        });

                        setEditorState(
                            flow([EditorState.push, EditorState.moveFocusToEnd, s => addNewBlockAt(s, blockKey)])(
                                editorState,
                                Modifier.replaceText(contentState, newSelection, ""),
                            ),
                        );

                        return HandlerStatus.HANDLED;
                    }
                }

                setEditorState(insertNewLine(editorState));
                return HandlerStatus.HANDLED;
            }
            return HandlerStatus.NOT_HANDLED;
        },

        handlePastedText(text, _html, editorState, { setEditorState }) {
            const block = getSelectedBlock(editorState);
            if (isCodeBlock(block)) {
                setEditorState(pasteText(editorState, text));

                return HandlerStatus.HANDLED;
            }

            return HandlerStatus.NOT_HANDLED;
        },

        onTab(event, { getEditorState, setEditorState }) {
            const editorState = getEditorState();
            if (getSelectedBlock(editorState).getType() !== "code-block") {
                return HandlerStatus.NOT_HANDLED;
            }

            event.preventDefault();

            setEditorState(insertIndent(editorState));
            return HandlerStatus.HANDLED;
        },
    };
};

export default codePlugin;
