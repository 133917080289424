import { EditorState, Modifier, SelectionState } from "draft-js";
import dynamic from "next/dynamic";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import theme from "src/uikit/theme";

import LANGUAGES from "../../../../Codemirror/languages";

const Codemirror = dynamic(() => import("../../../../Codemirror/Codemirror"), {
    ssr: false,
});

const CodeLanguageSelect = props => {
    const languagesListOptions = useMemo(() => {
        return LANGUAGES.map(({ name, value }) => {
            return (
                <option key={value} value={value}>
                    {name}
                </option>
            );
        });
    }, []);
    return (
        <select {...props}>
            <option value={null}>Language...</option>
            {languagesListOptions}
        </select>
    );
};

const CodeBlock = props => {
    const { block, blockProps, contentState } = props;
    const { getEditorState, setEditorState, setReadOnly } = blockProps || {};

    const editorRef = useRef(null);

    const blockData = block.getData();
    const currentLanguage = blockData.get("language");
    const currentContent = block.getText();
    const editorState = getEditorState();

    const mergeData = useCallback(
        data => {
            const newBlock = block.merge({
                data: block.getData().merge(data),
            });

            const newContentState = contentState.merge({
                blockMap: contentState.getBlockMap().set(block.getKey(), newBlock),
                selectionAfter: editorState.getSelection(),
            });

            setEditorState(EditorState.push(editorState, newContentState, "change-block-data"));
        },
        [editorState, setEditorState, block, blockData],
    );

    const handleLanguageChange = useCallback(
        evt => {
            mergeData({
                language: evt.target.value || null,
            });
        },
        [block, blockProps, contentState],
    );

    useEffect(() => {
        const editor = editorRef.current;
        const { selectionBefore } = editor || {};
        const beforeFocusKey = selectionBefore && selectionBefore.getFocusKey();
        const beforeAnchorKey = selectionBefore && selectionBefore.getAnchorKey();

        const selection = editorState.getSelection();
        const anchorKey = selection.getAnchorKey();
        const focusKey = selection.getFocusKey();

        const blockKey = block.getKey();

        if (
            beforeAnchorKey !== anchorKey &&
            beforeFocusKey !== focusKey &&
            anchorKey === focusKey &&
            anchorKey === blockKey &&
            editor
        ) {
            // Handle focus when deleting a block and new selection targets this block
            editor.focus();
        }

        if (editor) {
            editor.selectionBefore = selection;
        }
    }, [editorState]);

    const startEdit = useCallback(() => {
        setReadOnly(true);
    }, [setReadOnly, block]);

    const endEdit = useCallback(() => {
        setReadOnly(false);
    }, [setReadOnly, block]);

    const handleContentChange = useCallback(
        (_editor, _data, content) => {
            const key = block.getKey();
            const newContentState = Modifier.replaceText(
                contentState,
                SelectionState.createEmpty(key).merge({
                    anchorOffset: 0,
                    focusOffset: block.getLength(),
                }),
                content,
            );

            setEditorState(EditorState.push(getEditorState(), newContentState, "insert-characters"));
        },
        [setEditorState, getEditorState, block, contentState],
    );

    const handleKeyDown = useCallback(
        (editor, event) => {
            const index = editor.indexFromPos(editor.getCursor());
            const text = editor.getValue();
            const { keyCode } = event;

            // On "Enter"
            if (keyCode === 13) {
                // check if we are in the last line
                if (index === text.length) {
                    const trimEndRegex = /\s+$/g;
                    const trimMatch = text.match(trimEndRegex);

                    // and got 2 previous breaklines
                    if (trimMatch && trimMatch.length > 0 && (trimMatch[0].match(/\r?\n/g) || []).length > 1) {
                        editor.getInputField().blur();
                    }
                }
            }
            // On "Return"
            else if (keyCode === 8) {
                if (index === text.length && index === 0) {
                    editor.getInputField().blur();
                    // Blur the field and trigger default draftjs behaviour
                }
            }
        },
        [currentContent],
    );

    const setEditorRef = useCallback(editor => {
        editorRef.current = editor;
    }, []);

    return (
        <div style={{ position: "relative" }}>
            <CodeLanguageSelect
                style={{ position: "absolute", zIndex: theme.zIndex.navigation, top: 16, right: 16 }} // Using zIndex navigation property here to let dropdown menu pass over the code block
                value={currentLanguage}
                onChange={handleLanguageChange}
                onFocus={startEdit}
                onBlur={endEdit}
            />
            <Codemirror
                editorDidMount={setEditorRef}
                language={currentLanguage}
                onFocus={startEdit}
                onBlur={endEdit}
                value={currentContent}
                onBeforeChange={handleContentChange}
                onKeyDown={handleKeyDown}
            />
        </div>
    );
};

CodeBlock.displayName = "CodeBlock";

export default CodeBlock;
