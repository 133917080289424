import clsx from "clsx";
import { Text } from "src/uikit";
import styled from "styled-components";

const OrderedList = styled(Text).attrs(({ className }) => ({ className: clsx("pl-4", className) }))`
    list-style: decimal;

    & & {
        list-style: lower-alpha;
    }

    & & & {
        list-style: lower-roman;
    }
`;

OrderedList.defaultProps = {
    ...Text.defaultProps,
    as: "ol",
};

OrderedList.displayName = "OrderedList";

export default OrderedList;
