import clsx from "clsx";
import React, { useCallback } from "react";

import AddImageForm from "./AddImageForm";

const preventDefault = event => {
    event.preventDefault();
};

const AddImageButton = props => {
    const { theme, onOverrideContent, children } = props;
    // const hasLinkSelected = EditorUtils.hasEntity(
    //     this.props.store.getEditorState(),
    //     "LINK",
    // );
    // const className = hasLinkSelected
    //     ? clsx(theme.button, theme.active)
    //     : theme.button;

    const handleClick = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();

            const content = p => <AddImageForm {...p} {...props} />;
            onOverrideContent(content);
        },
        [onOverrideContent],
    );

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div className={theme.buttonWrapper} onMouseDown={preventDefault}>
            <button className={clsx(theme.button)} onClick={handleClick} type="button">
                {children}
            </button>
        </div>
    );
};

export default AddImageButton;
