import { EditorState, Modifier } from "draft-js";
import getIndentation from "draft-js-code/lib/utils/getIndentation";
import insertNewLine from "draft-js-code/lib/utils/insertNewLine";

export const insertIndent = editorState => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const currentBlock = contentState.getBlockForKey(startKey);

    const indentation = getIndentation(currentBlock.getText());
    let newContentState;

    if (selection.isCollapsed()) {
        newContentState = Modifier.insertText(contentState, selection, indentation);
    } else {
        newContentState = Modifier.replaceText(contentState, selection, indentation);
    }

    return EditorState.push(editorState, newContentState, "insert-characters");
};

export { insertNewLine };
