import clsx from "clsx";
import { Text } from "src/uikit";
import styled from "styled-components";

const UnorderedList = styled(Text).attrs(({ className }) => ({ className: clsx("pl-4", className) }))`
    list-style: disc;

    & & {
        list-style: circle;
    }

    & & & {
        list-style: square;
    }
`;

UnorderedList.defaultProps = {
    ...Text.defaultProps,
    as: "ul",
};

UnorderedList.displayName = "UnorderedList";

export default UnorderedList;
